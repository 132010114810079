@import url(https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
.pac-container,
.pac-item {
  z-index: 2147483647 !important;
}

#map {
  height: 100%;
}

.react-calendar {
  width: 100%;
  border: none;
}

.react-calendar__tile:hover {
  border-radius: 60px;
}

.react-calendar__tile--active {
  background: #006edc !important;
  color: white;
  border-radius: 50px;
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 1.7em 0.5em;
  background: none;
}

